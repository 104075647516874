<template>
  <v-container fluid>
    <v-card class="pa-4">
      <h2 class="text-h6 mb-4">Roles Usuarios Expedientes</h2>

      <div class="d-flex flex-wrap mb-4" style="gap: 20px;">
        <!-- Filtro por Bodega con buscador dinámico -->
        <v-autocomplete v-if="isAdmin" v-model="filtroBodega" :items="bodegas" item-text="nombreBodega"
          item-value="codigoBodega" label="Filtrar por Bodega" outlined dense class="flex-grow-1"
          prepend-inner-icon="mdi-warehouse" :menu-props="{ offsetY: true, maxHeight: '300px' }"
          @change="cargarUsuariosPorBodega" />
        <!-- Filtro por Rol con autocomplete -->
        <v-autocomplete v-model="filtroRol" :items="listaRolesBase" label="Filtrar por Rol" outlined dense clearable
          class="flex-grow-1" prepend-inner-icon="mdi-account-badge-outline"
          :menu-props="{ offsetY: true, maxHeight: '300px' }" />

        <!-- Búsqueda por afiliado -->
        <v-text-field v-model="search" label="Buscar afiliado..." append-inner-icon="mdi-magnify" outlined dense
          clearable class="flex-grow-1"></v-text-field>
      </div>

      <v-divider></v-divider>
      <!-- Tabla -->
      <v-data-table :headers="headers" :items="usuariosFiltrados" :items-per-page="10" class="elevation-2"
        no-data-text="No hay usuarios disponibles">
        <template #item.roles="{ item }">
          <div>{{ item.roles.join(', ') }}</div>
        </template>

        <template #item.estadoUsuario="{ item }">
          <v-chip :color="item.estadoUsuario === 'true' ? 'green' : 'red'" dark>
            {{ item.estadoUsuario === 'true' ? 'Activo' : 'Inactivo' }}
          </v-chip>
        </template>

        <template #item.actions="{ item }">
          <v-btn color="primary" @click="abrirEditar(item)" icon>
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>



    <!-- Modal para editar roles -->
    <!-- Modal para editar roles -->
    <v-dialog v-model="dialogEditar" max-width="500px">
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          Editar Roles
        </v-card-title>

        <v-card-text class="mt-4">
          <p><strong>Usuario:</strong> {{ usuarioSeleccionado.usuario }}</p>
          <p><strong>Nombre:</strong> {{ usuarioSeleccionado.nombre }}</p>
          <p><strong>Rol asignado:</strong> {{ usuarioSeleccionado.roles?.join(', ') || 'Sin roles' }}</p>

          <p class="mt-4"><strong>Cambiar rol por:</strong></p>
          <v-select v-model="rolesSeleccionados" :items="rolesDisponibles" label="Seleccionar roles" item-value="value"
            item-text="text"></v-select>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogEditar = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarRoles">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="loading" v-if="loading">
      <div class="loadingio-spinner-spinner-2by998twmg8">
        <div class="ldio-yzaezf3dcmj">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <h4>{{ loadingText }}</h4>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { mdiPencil } from "@mdi/js"; // Importar íconos de MDI
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      icons: {
        mdiPencil: mdiPencil
      },
      filtroBodega: null,
      filtroRol: null,
      bodegas: [],
      bodegaId: "",
      nombreBodega: "",
      idEmpresa: "",
      loading: true,
      usuarios: [],
      search: "",
      dialogEditar: false,
      usuarioSeleccionado: {},
      rolesSeleccionados: [],
      loadingText: 'Cargando usuarios',
      isAdmin: false,
      dataEmpresas: "",
      listaRolesBase: [
        { value: 'Expediente_Digitalizador', text: 'Digitalizador' },
        { value: 'Expediente_Regente', text: 'Regente' },
        { value: 'Expediente_Auditor', text: 'Auditor' },
        { value: 'Expediente_Radicador', text: 'Radicador' },
        { value: 'Expediente_Caja', text: 'Caja' },
        { value: 'Expediente_Administrador', text: 'Administrador' },
      ],
      listaRoles: []
      ,
      headers: [
        { text: "Usuario", value: "usuario" },
        { text: "Nombre", value: "nombre" },

        { text: "Rol Asignado", value: "roles" },
        { text: "Bodega", value: "bodega.nombreBodega" },
        { text: "Estado Usuario", value: "estadoUsuario" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    rolesDisponibles() {
      if (!this.usuarioSeleccionado?.roles) return this.listaRoles;
      return this.listaRoles.filter(
        rol => !this.usuarioSeleccionado.roles.includes(rol.value)
      );
    },
    usuariosFiltrados() {
      return this.usuarios.filter((user) => {
        const bodegaOk = !this.filtroBodega || user.bodega.bodegasId.codigoBodega === this.filtroBodega;
        const rolOk = !this.filtroRol || (user.roles && user.roles.includes(this.filtroRol));
        const searchTerm = this.search ? this.search.toLowerCase() : '';
        const searchOk =
          !searchTerm ||
          user.usuario.toLowerCase().includes(searchTerm) ||
          user.nombre.toLowerCase().includes(searchTerm);

        return bodegaOk && rolOk && searchOk;
      });
    }

  },
  mounted: async function () {
    const vuexState = JSON.parse(localStorage.getItem('vuex'));

    this.usuario = this.auth.username;
    await this.empresasUsuario();
    this.getBodega();
    if (vuexState.auth.roles.includes("Expediente_Administrador")) {
      this.isAdmin = true;
      this.addTitle = "Administrador";
      this.getBodegas();
      console.log("roles = " + this.listaRoles)
       this.listaRoles = this.listaRolesBase;
    } else {
      this.listaRoles = this.listaRolesBase.filter(
        (rol) => rol.value !== 'Expediente_Regente'
      );
    }
  },
  methods: {
    ...mapMutations(["showSuccess", "showError"]),
    getBodegas() {
      const idEmpresa = this.dataEmpresas[0].idEmpresa;
      this.$http.get('msa-process-file/api/bodega/list/' + idEmpresa)
        .then(({ data }) => {
          console.log(data)
          this.bodegas = data;
        })
        .catch(err => console.log(err))
    },
    abrirEditar(item) {
      this.usuarioSeleccionado = item;
      this.rolesSeleccionados = []; // Puedes precargar roles si ya vienen
      this.dialogEditar = true;
      console.log(this.usuarioSeleccionado.usuario)
    },
    guardarRoles() {
      console.log("Guardando roles para:", this.usuarioSeleccionado.usuario);
      console.log("Roles seleccionados:", this.rolesSeleccionados);

      const usuario = this.usuarioSeleccionado.usuario;
      const rolAnterior = this.usuarioSeleccionado.roles && this.usuarioSeleccionado.roles.length > 0
        ? this.usuarioSeleccionado.roles[0]
        : null;
      const rolNuevo = this.rolesSeleccionados;

      const payload = {
        'nombres': this.usuarioSeleccionado.nombre,
        'username': usuario,
        'rolAnterior': rolAnterior,
        'rolNuevo': rolNuevo,
      };

      this.$http
        .post('msa-process-file/api/usuario/actualizarrol', payload)
        .then((result) => {
          console.log(result);
          if (result && result.data) {
            // ✅ Actualizar el rol localmente en el objeto usuarioSeleccionado
            this.usuarioSeleccionado.roles = [rolNuevo];

            // ✅ Actualizar en la tabla (usuarios)
            const index = this.usuarios.findIndex(u => u.usuario === usuario);
            if (index !== -1) {
              this.usuarios[index].roles = [rolNuevo];
            }

            // ✅ Cerrar el diálogo y mostrar alerta
            this.dialogEditar = false;
            Swal.fire({
              title: 'Rol Expediente Usuario',
              text: result.data,
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
          } else {
            Swal.fire({
              title: 'Rol Expediente Usuario',
              text: 'No se recibió respuesta del servidor',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          }
        })
        .catch(error => {
          console.error("Error al actualizar:", error);
          Swal.fire({
            title: 'Error',
            text: 'Error al actualizar el rol',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        });
    },
    async empresasUsuario() {
      const codEmpresas = await this.$http
        .get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => (this.codigosEmpresas = data))
        .catch((err) => console.log(err));

      if (codEmpresas != []) {
        await this.$http
          .post("msa-setting/api/empresa/list", this.codigosEmpresas)
          .then(({ data }) => (this.dataEmpresas = data))
          .catch((err) => console.log(err));
      }
    },
    getBodega() {
      this.$http
        .get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          this.bodegaId = data.codigoBodega;
          this.nombreBodega = data.nombreBodega;
          this.idEmpresa = data.idEmpresa;
          this.getUsuarios();
        })
        .catch((err) => console.log(err));
    },
    cargarUsuariosPorBodega() {
      this.loading = true;
      if (this.filtroBodega) {
        this.bodegaId = this.filtroBodega;
        this.getUsuarios();
      } else {
        this.usuarios = [];
      }
    },
    getUsuarios() {
      this.$http
        .get(`msa-process-file/api/usuario/list`, {
          params: {
            idEmpresa: this.idEmpresa,
            codigoBodega: this.bodegaId,
          },
        })
        .then(({ data }) => {
          console.log(data)
          this.loading = false;
          this.usuarios = data;
        })
        .catch((err) => console.log(err));
    },
  }
};
</script>

<style scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 53px);
  background-color: rgba(0, 0, 0, .5);
  z-index: 2;
}

.loading h4 {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}

.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}

.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}
</style>
